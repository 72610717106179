import { Container, Row, Col, Image } from "react-bootstrap";
import { useRef } from "react";
import useFadeIn from "../../hooks/useFadeIn";
import CardTransparent from "../CardTransparent";

export default function Von(){
    
    const vonRef = useRef<HTMLDivElement>(null);
    const vonVisible = useFadeIn(vonRef);

    return (
        <div id="von" className="text-white py-5">
            <Container className="px-lg-5">
                <Row className="pb-5">
                    <Col lg={6}>
                        <div className="d-flex align-items-center" style={{height: '100%'}}>
                            <div className={`d-flex flex-row align-items-start ${vonVisible ? "fadeIn" : "fadeInHidden"}`} ref={vonRef}>
                                <Image src={process.env.PUBLIC_URL + "/seta-a-von.png"} className="me-3 mt-2" style={{width: 53}}/>
                                <div>
                                    <h2 className="text-primary fw-bold fs-1">A Von</h2>
                                    <p className="fs-6">
                                        Von é inovação, segurança e praticidade para assembleias virtuais de credores. Uma plataforma inteligente, 100% digital, descomplicada, que oferece todas as ferramentas necessárias para assembleias por videoconferência.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Image fluid src={process.env.PUBLIC_URL + "Mock_app_ 1.png"}/>
                    </Col>
                </Row>
                <Row className="py-2 my-5">
                    <Col lg={6}>
                        <CardTransparent>
                            <Image src={process.env.PUBLIC_URL + "/icon-seguranca.png"} className="me-3" style={{width: 53}}/>
                            <h3 className="mb-0">Segurança</h3>
                            <p>Com padrões e certificações de segurança e privacidade  de nível empresarial, além de recursos e criptografia para ajudar a manter os dados seguros, a plataforma possibilita a autenticação de acesso dos participantes em cada ato assemblear, além da disponibilização do comprovante de votação individualizado na própria plataforma.</p>
                        </CardTransparent>
                    </Col>
                    <Col lg={6}>
                        <CardTransparent>
                            <Image src={process.env.PUBLIC_URL + "/icon-transparencia.png"} className="me-3" style={{width: 53}}/>
                            <h3 className="mb-0">Transparência</h3>
                            <p>As assembleias têm transmissão ao vivo pelo Canal no YouTube da Von Meeting. A Von possibilita o acompanhamento em tempo real da Assembleia pelo Juízo e Ministério Público, e disponibiliza, também em tempo real e automaticamente, o resultado e laudos de presença e votação.</p>
                        </CardTransparent>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}