import { ReactNode } from "react";
import { Container } from "react-bootstrap";

import Header from "../Header";
import Footer from "../Footer";

type LayoutProps = {
    children: ReactNode;
}

export default function Layout(props : LayoutProps){

    return (
        <Container fluid>
            <Header/>
            {props.children}
            <Footer/>
        </Container>
    )
}