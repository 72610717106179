import { ReactNode } from "react";
import { Card } from "react-bootstrap";

type CardTransparentProps = {
    children: ReactNode;
}

export default function CardTransparent(props : CardTransparentProps){

    return (
        <Card
            className="border-0 text-white p-4 mb-4"
            style={{
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '24px',
                background: 'linear-gradient(222deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%)',
                boxShadow: '4px 4px 19px 0px rgba(0, 0, 0, 0.19)',
                backdropFilter: 'blur(22.5px)',
            }}
        >
            <Card.Body className="d-flex flex-column gap-4">
                {props.children}
            </Card.Body>
        </Card>
    )
}