import { Container } from "react-bootstrap";
import Layout from "../../components/Layout";

export default function PoliticaDePrivacidade(){

    return (
        <Layout>
            <main>
                <div id="home" className="d-flex flex-column justify-content-center align-items-center text-white pb-5">
                    <Container className="px-lg-5 d-flex flex-column fadeIn">
                        <h2 className="text-primary fw-bold fs-1 text-center">Política de Privacidade</h2>
                        <p>Sua privacidade é muito importante para nós e queremos dar total transparência em nossa prestação de serviço, bem como esclarecer os critérios e dados coletados a fim de prestar um serviço de qualidade. </p>
                        <p>Nosso time está constantemente debruçado sobre o tema da proteção de dados, buscando sempre adequar as nossas estruturas à nova legislação (Lei 13.790/18 – Lei Geral de Proteção de Dados – “LGPD”). </p>
                        <p>Na prática, isso significa que seguimos tratando os seus dados pessoais de forma adequada e segura, mas passamos a adotar novas dinâmicas capazes de documentar esse zelo e atender a direitos assegurados a você pela LGPD. </p>
                        <p>Abaixo segue nossa Política de Privacidade, que demonstra o compromisso em zelar por seus dados pessoais.  </p>
                        <p>1.    Esclarecimentos preliminares </p>
                        <p>Para fins desta Política, a expressão “dados pessoais” designa quaisquer informações relacionadas a você e que permitam a sua identificação, tais como, conforme o caso, o seu nome, endereço, e-mail, CPF/RG, histórico de navegação no nosso aplicativo, telefone ou outras informações pessoais que possam ser fornecidas por você ou coletadas de acordo com a legislação aplicável.  </p>
                        <p>2.    Quais são os princípios e fundamentos que seguimos ao tratar os seus dados? </p>
                        <p>A VonMeeting realiza o tratamento de seus dados pessoais de acordo com os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade de dados, segurança, prevenção, não discriminação e, mais importante, transparência.  </p>
                        <p>Qualquer tratamento de dados pessoais, sensíveis ou não, realizado por nós estará baseado em fundamento legal e se dará de forma adequada com a finalidade da sua coleta. Nesse sentido, o tratamento de seus dados pessoais ocorrerá para: (i) cumprimento de obrigações legais ou regulatórias, (ii) exercício regular de direitos em processos, (iii) quando necessários para execução de contrato firmado com você, ou de procedimentos necessários para celebração deste, (iv) proteção de crédito, (v) atender interesses legítimos da VonMeeting ou (vii) prevenir fraudes. Em outros casos, o tratamento de dados pessoais será condicionado ao seu consentimento. </p>
                        <p>3.    Quais dados pessoais são coletados e tratados? </p>
                        <p>A VonMeeting, no exercício de suas funções e a depender do caso concreto, poderá coletar e tratar, conforme o caso: </p>
                        <p>Dados cadastrais, como, mas não limitado a, nome completo, endereço residencial, data de nascimento, sexo, número do CPF/RG, telefone de contato e endereço de e-mail; </p>
                        <p>Informações e opiniões colhidas em pesquisas; </p>
                        <p>Informações manifestamente públicas; </p>
                        <p>Dados biométricos. </p>
                        <p>A VonMeeting, no exercício de suas funções e a depender do caso concreto, poderá coletar e tratar, conforme o caso: (…) Informações precisas de geolocalização de funcionalidade de GPS / IP do seu dispositivo móvel. </p>
                        <p>4.    Quais as finalidades dos tratamentos de dados pessoais realizados? </p>
                        <p>A VonMeeting conta com finalidades legítimas e específicas para todos os tratamentos de dados pessoais que realiza, sendo que tais finalidades irão depender do tipo de relacionamento que nós possuímos com você e da natureza do serviço que você utiliza. </p>
                        <p>Nós também poderemos tratar os seus dados pessoais para fins de realizar ações de marketing e de comunicação, tais como envio de informações que possam ser relevantes a você, gestão das nossas redes sociais e aplicativos, promoção de oficinas / eventos institucionais / workshops e aplicação de pesquisas de satisfação. </p>
                        <p>Na hipótese de ser planejado um tratamento posterior de dados pessoais para uma finalidade diferente daquela para a qual os dados foram coletados, informações sobre essa outra finalidade serão fornecidas a você antes do tratamento posterior, inclusive para que você possa dar seu consentimento, caso necessário. </p>
                        <p>Os seus dados pessoais também poderão ser tratados para viabilizar a utilização de todas as funcionalidades oferecidas pela nossa plataforma, garantindo a segurança de suas informações e prevenindo possíveis fraudes no uso da plataforma. </p>
                        <p>5.    Com quem seus dados podem ser compartilhados? </p>
                        <p>Precisamos compartilhar seus dados pessoais com diferentes interessados, sempre que necessário para o adequado fornecimento do serviço contratado por você, para o exercício de nossas atividades ou para o cumprimento da legislação aplicável. Estes terceiros serão sempre obrigados a utilizar os seus dados única e exclusivamente para a finalidade contratada, mantendo o mesmo dever de sigilo e confidencialidade.   </p>
                        <p>Note que, em razão do nosso modelo de integração e transparência, é possível que haja o compartilhamento de seus dados pessoais com nossos colaboradores, credores, recuperandas e no processo judicial para a correta execução dos propósitos e finalidades descritos acima.  </p>
                        <p>Nossos colaboradores, credores e recuperandas que acessem os seus dados pessoais estão obrigados a respeitar a sua privacidade e proibidos de fazerem uso dessas informações de forma distinta à definida por esta Política.  </p>
                        <p>6.    Quais são os seus direitos enquanto titular dos dados que nós tratamos? </p>
                        <p>Sem prejuízo de outros direitos conferidos pela legislação brasileira aplicável, você possui os seguintes direitos no que se refere aos seus dados pessoais: </p>
                        <p>I.      Confirmação da existência de tratamento de seus dados pessoais; </p>
                        <p>II.     Acesso aos dados pessoais tratados por nós; </p>
                        <p>III.    Correção de dados incompletos, inexatos ou desatualizados; </p>
                        <p>IV.    Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD; </p>
                        <p>V.     Portabilidade de seus dados pessoais a outro prestador de serviço; </p>
                        <p>VI.    Informações sobre uso compartilhado de seus dados pessoais; </p>
                        <p>VII.   Informação sobre a possibilidade de não fornecer consentimento para os tratamentos de dados pessoais que dependam deste ato; </p>
                        <p>VIII.  Revogação do consentimento, quando aplicável. </p>
                        <p>Desde logo, caso deseje exercer quaisquer dos direitos elencados acima, você deverá entrar em contato pelo e-mail vonmeeting@outlook.com e informar a sua solicitação.  </p>
                        <p>7.    Por quanto tempo ficamos em posse de seus dados? </p>
                        <p>Armazenamos e mantemos os seus dados pessoais: </p>
                        <p>I.    Pelo tempo exigido por lei; </p>
                        <p>II.   Até o término / esgotamento da finalidade de cada assembleia-geral de credores; </p>
                        <p>III.  Pelo tempo necessário a preservar o legítimo interesse da VonMeeting. </p>
                        <p>9.    Que precauções foram implementadas para proteger os seus dados? </p>
                        <p>Para proteger as suas informações, adotamos medidas de segurança para o tratamento de todos os dados pessoais coletados, sobretudo os dados pessoais sensíveis. Essas providências técnicas e organizacionais integram a nossa Política de Segurança da Informação e foram implementadas para prevenir o acesso não autorizado, a manipulação acidental ou intencional, a perda ou a destruição de seus dados pessoais. </p>
                        <p>8. DPO (Data Protection Officer) Caso você tenha qualquer dúvida ou solicitação referente ao tratamento dos seus dados pessoais, entre em contato por meio do e-mail vonmeeting@outlook.com. </p>
                    </Container>
                </div>
            </main>
        </Layout>
    )
}