import { Card, Col, Container, Image, Row } from "react-bootstrap";

export default function Credores(){
    
    return (
        <div id="credores" className="text-white p-lg-5 my-5">
            <Container className="p-lg-5">
                <Card
                    className="border-0 text-white p-4 mx-lg-5"
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: '24px',
                        background: 'linear-gradient(200deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%)',
                        boxShadow: '4px 4px 19px 0px rgba(0, 0, 0, 0.19)',
                        backdropFilter: 'blur(22.5px)',
                    }}
                >
                    <Card.Body>
                        <Row>
                            <Col lg={5} className="d-flex">
                                <Image src={process.env.PUBLIC_URL + "/Mockup_notebook 1.png"} className="object-fit-contain d-none d-lg-block" style={{width: "120%", marginLeft: '-20%'}}/>
                                <Image fluid src={process.env.PUBLIC_URL + "/Mockup_notebook 1.png"} className="object-fit-contain d-lg-none mb-4"/>
                            </Col>
                            <Col lg={7}>
                                <div className={`d-flex flex-row align-items-start`}>
                                    <Image src={process.env.PUBLIC_URL + "/seta-funcionalidades.png"} className="mt-2 me-3" />
                                    <div className="funcionalidades">
                                        <h2 className="fw-bold mb-4">Credores</h2>
                                        <p className="fw-medium">Na Von, os credores têm à disposição:</p>
                                        <ul className="fw-light">
                                            <li>Uma plataforma democratizadora de acesso às assembleias</li>
                                            <li>Acesso simplificado, rápido, seguro e intuitivo pelo site e/ou aplicativo</li>
                                            <li>Credenciamento para o credor e seu representante legal</li>
                                            <li>Possibilidade de participação na assembleia exclusivamente pelo aplicativo</li>
                                            <li>Sistema de votação integrado à plataforma</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}