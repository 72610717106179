import { ReactNode } from "react";
import { Card } from "react-bootstrap";

type CardDarkProps = {
    children: ReactNode;
}

export default function CardDark(props : CardDarkProps){

    return (
        <Card
            className="border-0 text-white p-3 shadow"
            style={{
                height: '100%',
                fontSize: 18,
                fontWeight: 500,
                lineHeight: '24px',
                background: 'linear-gradient(200deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%)',
                backdropFilter: 'blur(22.5px)',
            }}
        >
            <Card.Body className="d-flex flex-column justify-content-start align-items-start gap-2">
                {props.children}
            </Card.Body>
        </Card>
    )
}