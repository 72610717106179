import { Nav, Navbar, Image, Button, Container } from "react-bootstrap";

export default function Header(){

    return (
        <header>
            <Navbar expand="lg" variant="dark" fixed="top" bg="dark">
                <Container>
                    <Navbar.Brand href="/#home"><Image src={process.env.PUBLIC_URL + "/logo_von.png"} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto align-items-center gap-2">
                        <Nav.Link href="/#von">A Von</Nav.Link>
                        <Nav.Link href="/#funcionalidades">Funcionalidades</Nav.Link>
                        <Nav.Link href="/#credores">Credores</Nav.Link>
                        <Nav.Link href="/#administracao">Administração Judicial</Nav.Link>
                        <Nav.Link href="/#recuperandas">Recuperandas</Nav.Link>
                        <Nav.Link href="https://gerenciador.vonmeeting.com.br/">
                            <Button className="text-uppercase py-3 px-4">acessar</Button>
                        </Nav.Link>
                        <Nav.Link href="https://api.whatsapp.com/send?phone=5551980887890&text=Gostaria%20de%20conhecer%20um%20pouco%20mais%20sobre%20VonMeeting,%20poderias%20enviar%20informa%C3%A7%C3%B5es%20e%20valores%20para%20contrata%C3%A7%C3%A3o?" target="_blank">
                            <Button variant="outline-primary" className="text-uppercase py-3 px-4">solicite um orçamento</Button>
                        </Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}