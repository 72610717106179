import { useRef, useLayoutEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import useFadeIn from "../../hooks/useFadeIn";
import CardWhite from "../CardWhite";

export default function Funcionalidades(){

    const funcionalidadesRef = useRef<HTMLDivElement>(null);
    const funcionalidadesCardsContainerRef = useRef<HTMLDivElement>(null);
    const funcionalidadesCardsRef = useRef<HTMLDivElement>(null);
    const funcionalidadesCardsSpaceRef = useRef<HTMLDivElement>(null);
    const funcionalidadesVisible = useFadeIn(funcionalidadesRef);

    useLayoutEffect(()=>{
        if(funcionalidadesCardsRef.current && funcionalidadesCardsContainerRef.current && funcionalidadesCardsSpaceRef.current){
            funcionalidadesCardsRef.current.style.left = `${funcionalidadesCardsContainerRef.current.offsetLeft}px`;
            funcionalidadesCardsSpaceRef.current.style.width = `${funcionalidadesCardsRef.current.clientWidth-450}px`;
        }
    },[funcionalidadesCardsRef, funcionalidadesCardsContainerRef, funcionalidadesCardsSpaceRef]);

    function handleAnterior(){
        if(funcionalidadesCardsRef.current){
            funcionalidadesCardsRef.current.scrollLeft = funcionalidadesCardsRef.current?.scrollLeft-450;
        }
    }

    function handleProxima(){
        if(funcionalidadesCardsRef.current){
            funcionalidadesCardsRef.current.scrollLeft = funcionalidadesCardsRef.current?.scrollLeft+450;
        }
    }

    return (
        <div id="funcionalidades" className="text-dark py-5 overflow-hidden position-relative">
            <Container className="p-lg-5 pe-lg-0">
                <Row>
                    <Col md={5} className="d-flex align-items-center mb-4">
                        <div className={`d-flex flex-row align-items-start ${funcionalidadesVisible ? "fadeIn" : "fadeInHidden"}`} ref={funcionalidadesRef}>
                            <Image src={process.env.PUBLIC_URL + "/seta-funcionalidades.png"} className="mt-2 me-3" />
                            <div className="funcionalidades">
                                <h2 className="text-dark fw-bold mb-4">Funcionalidades</h2>
                                <p className="fs-6">
                                    <b>Multifuncional, prática, segura e interativa.</b> <br/>
                                    Com uma interface rápida, leve e intuitiva, além da possibilidade de votação na própria plataforma e compartilhamento de documentos, na Von você tem um ambiente exclusivo e otimizado para realizar assembleias.
                                    <b> E mais:</b>
                                </p>
                                <div>
                                    <Image src={process.env.PUBLIC_URL + "/seta-esquerda.png"} className="me-3" onClick={handleAnterior} style={{cursor: 'pointer'}}/>
                                    <Image src={process.env.PUBLIC_URL + "/seta-direita.png"} className="me-3" onClick={handleProxima} style={{cursor: 'pointer'}}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={7} style={{height: 380}} ref={funcionalidadesCardsContainerRef}>
                        <div className="funcionalidades-cards d-flex align-items-center" ref={funcionalidadesCardsRef}>
                            <div className="d-flex flex-nowrap">
                                <CardWhite
                                    src={process.env.PUBLIC_URL + "/GettyImages-1300836708-1.png"}
                                    title={<span>Assembleias de <br/> áudio e vídeo</span>}
                                    text="Vídeo e áudio para as assembleias virtuais com suporte ilimitado."
                                />
                                <CardWhite
                                    src={process.env.PUBLIC_URL + "/GettyImages-1300836708-2.png"}
                                    title={<span>Ferramentas <br/> integradas</span>}
                                    text="Os participantes das assembleias podem acompanhar o compartilhamento de telas e proporcionar uma assembleia virtual mais interativa."
                                />
                                <CardWhite
                                    src={process.env.PUBLIC_URL + "/GettyImages-1300836708-3.png"}
                                    title={<span>Feito para <br/> conectar</span>}
                                    text="Reações, chat, botão de levantamento de mãos e votações integradas à plataforma tornam as assembleias virtuais mais dinâmicas, produtivas e eficientes."
                                />
                                <CardWhite
                                    src={process.env.PUBLIC_URL + "/Group 18.png"}
                                    title={<span>Chat <br/> </span>}
                                    text="Conversa privada, em grupos, histórico pesquisável e compartilhamento de arquivo."
                                />
                                <CardWhite
                                    src={process.env.PUBLIC_URL + "/GettyImages-1300836708 5.png"}
                                    title={<span>Transmissão ao <br/> vivo pelo YouTube</span>}
                                    text="Transmissão ao vivo da assembleia pelo Canal no YouTube da Von Meeting, com transcrições das discussões no chat da plataforma."
                                />
                                <div ref={funcionalidadesCardsSpaceRef}>&nbsp;</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}