import { RefObject, useEffect, useMemo, useState } from "react";

export default function useFadeIn(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(([entry]) => {
        if(entry.isIntersecting){
            setIntersecting(true);
        }
    },{
        rootMargin: "-300px 0px 0px 0px"
    }), [])
  
    useEffect(() => {
        if(ref.current){
            observer.observe(ref.current)
            return () => {
                observer.disconnect()
            }
        }
    }, [ref]);
  
    return isIntersecting;
}