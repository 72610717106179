import Layout from "../../components/Layout";
import Funcionalidades from "../../components/Funcionalidades";
import Von from "../../components/Von";
import Banner from "../../components/Banner";
import Credores from "../../components/Credores";
import Administradora from "../../components/Administradora";
import Recuperandas from "../../components/Recuperandas";

export default function Landing(){

    return (
        <Layout>
            <main>
                <Banner/>
                <Von/>
                <Funcionalidades/>
                <Credores/>
                <Administradora/>
                <Recuperandas/>
            </main>
        </Layout>
    )
}