import { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import useFadeIn from "../../hooks/useFadeIn";
import CardDark from "../CardDark";

export default function Administradora(){

    const administradoraRef = useRef<HTMLDivElement>(null);
    const administradoraVisible = useFadeIn(administradoraRef);

    return (
        <div id="administracao" className="text-white py-5">
            <Container className="p-lg-5 pe-lg-0">
                <Row className="pb-5">
                    <Col md={{offset: 1, span: 5}}>
                        <div className={`d-flex flex-row align-items-start ${administradoraVisible ? "fadeIn" : "fadeInHidden"}`} ref={administradoraRef}>
                            <Image src={process.env.PUBLIC_URL + "/seta-funcionalidades.png"} className="mt-2 me-3" />
                            <div>
                                <h2 className="text-white fw-bold mb-4">Administradora<br/>Judicial</h2>
                                <p className="fs-6">
                                    Para as Administradoras Judiciais, a <b className="text-primary">Von</b> disponibiliza todas as ferramentas para a realização de assembleias de credores:
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-5">
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Importação automatizada da relação de credores por meio de planilha em Excel
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Desnecessidade de cadastramento individualizado dos credores
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Sistema de votação predeterminado à ordem do dia ou flexível a outras deliberações, conforme quóruns dos arts. 42 e 45 da Lei n.º 11.101/05
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Elaboração automatizada dos quóruns de presença e votação em tempo real
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Disponibilização do histórico de conversas do Chat após o encerramento da assembleia
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Homologação do credenciamento dos credores e da recuperanda pela plataforma
                        </CardDark>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <CardDark>
                            <div className="dot mb-2"/>
                            Concentração de todos os atos, maior gestão e melhor controle das assembleias
                        </CardDark>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}