import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import useFadeIn from "../../hooks/useFadeIn";

export default function Recuperandas(){

    const recuperandasRef = useRef<HTMLDivElement>(null);
    const recuperandasVisible = useFadeIn(recuperandasRef);

    return (
        <div id="recuperandas" className="text-dark py-5">
            <Container className={`p-lg-5 pe-lg-0 ${recuperandasVisible ? "fadeIn" : "fadeInHidden"}`} ref={recuperandasRef}>
                <Row className="pb-4">
                    <Col md={{offset: 1, span: 10}}>
                        <div className={`d-flex flex-row align-items-start`}>
                            <Image src={process.env.PUBLIC_URL + "/seta-funcionalidades.png"} className="mt-2 me-3" />
                            <div>
                                <h2 className="fw-bold mb-4">Recuperandas</h2>
                                <p className="fs-6">
                                    Para as empresas em Recuperação Judicial, a <b className="text-secondary">Von</b> de forma exclusiva, oferece:
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col md={{offset: 2, span: 8}}>
                        <div className={`d-flex flex-row align-items-start`}>
                            <Image src={process.env.PUBLIC_URL + "/Vector.png"} className="mt-2 me-3" />
                            <div>
                                <p className="fs-5 fw-medium">
                                    Solução exclusiva e inovadora por ser a primeira plataforma no Brasil para realização de assembleia de credores virtual com sistemas de vídeo e votação integrados
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{offset: 2, span: 8}}>
                        <div className={`d-flex flex-row align-items-start`}>
                            <Image src={process.env.PUBLIC_URL + "/Vector.png"} className="mt-2 me-3" />
                            <div>
                                <p className="fs-5 fw-medium">
                                    Todos os atos da assembleia centralizados pela plataforma <span className="text-secondary">Von Meeting:</span>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-lg-5 pt-lg-3 p-4 pt-0">
                    <Col md={{offset: 1, span: 10}}>
                        <Card
                            className="border-0 text-dark p-3 p-lg-4"
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeight: '24px',
                                backgroundColor: '#C2F94B',
                                boxShadow: '4px 4px 19px 0px rgba(0, 0, 0, 0.19)',
                                backdropFilter: 'blur(22.5px)',
                            }}
                        >
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <ul className="fw-light me-4">
                                            <li className="mb-3">Credenciamento simplificado e seguro para a Recuperanda e seus representantes legais exclusivamente pela plataforma</li>
                                            <li className="mb-3">Disponibilização dos principais documentos (Edital de convocação, Plano de Recuperação Judicial, Modificativos, Atas anteriores, etc.)</li>
                                        </ul>
                                    </Col>
                                    <Col>
                                        <ul className="fw-light">
                                            <li className="mb-3">Ferramenta para compartilhamento de documentos</li>
                                            <li className="mb-3">Votação rápida, segura e transparente</li>
                                            <li className="mb-3">Maior acessibilidade às assembleias pelos credores</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}