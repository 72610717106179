import { Container, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Banner(){
    
    return (
        <div id="home" className="d-flex flex-column justify-content-center align-items-center text-white pb-5">
            <Container className="px-lg-5 d-flex flex-column justify-content-center align-items-center fadeIn">
                <div className="d-flex flex-column gap-5">
                    <h1 className="px-4 px-lg-5">
                        <b>Inovação, segurança </b> <br/>
                        <b>e praticidade </b> para sua <br/>
                        <label className="text-primary"> assembleia virtual.</label>
                    </h1>
                    <div className="px-4 px-lg-5 d-flex flex-column flex-md-row align-items-start gap-3">
                        <Link to={"https://gerenciador.vonmeeting.com.br/"}><Button className="text-uppercase fw-medium py-2 px-5 fs-6">VERSÃO WEB</Button></Link>
                        {/* <Link to={"https://gerenciador.vonmeeting.com.br/"}><Image fluid src={process.env.PUBLIC_URL + "/google-play-badge.png"}/></Link>
                        <Link to={"https://gerenciador.vonmeeting.com.br/"}><Image fluid src={process.env.PUBLIC_URL + "/disponivel-na-app-store-botao.png"}/></Link> */}
                    </div>
                </div>
            </Container>
        </div>
    )
}