import { Card } from "react-bootstrap";

type CardWhiteProps = {
    src: string;
    title: any;
    text: any;
}

export default function CardWhite(props : CardWhiteProps){

    return (
        <Card
            bg="white"
            className="border-0 shadow"
            style={{
                width: 450,
                flexShrink: 0,
                scrollMarginLeft: '3rem !important',
                scrollSnapAlign: 'none start',
                scrollMarginInlineStart: '2rem',
                whiteSpace: 'normal',
                marginLeft: '3rem',
            }}
        >
            <Card.Img variant="top" src={props.src} style={{height: 270}}/>
            <Card.Body className="px-4 position-relative">
                <Card.Title
                    className="position-absolute text-primary fw-bold fs-3"
                    style={{
                        bottom: 125,
                    }}
                >
                    {props.title}
                </Card.Title>
                <Card.Text
                    className="fs-6"
                >
                    {props.text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}