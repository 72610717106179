import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Landing from "./screens/Landing";
import PoliticaDePrivacidade from './screens/PoliticaDePrivacidade';

import './styles/style.scss';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;